<!--事件处理-执法处理-->
<template>
  <section>
    <!-- 工具条 -->
    <el-row>
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filter" size="mini">
          <el-form-item>
            <el-select
              placeholder="处理状态"
              @change="getData"
              v-model="filter.status"
              clearable
              filterable>
              <el-option label="待处理" :value="0"></el-option>
              <el-option label="已完成" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-cascader
              placeholder="所属单位"
              @change="getData"
              v-model="filter.owner"
              :options="customerTree"
              :props="customerProps"
              clearable
              filterable/>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="filter.start_time"
              type="date"
              @change="getData"
              placeholder="派单日期"
              value-format="timestamp">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select
              @change="getData"
              v-model="filter.management_type"
              filterable
              clearable
              placeholder='派单类型'
            >
              <el-option :value="1" label="例行巡检"/>
              <el-option :value="2" label="现场监督"/>
              <el-option :value="3" label="执法管理"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleDialogOpen(null,'新增',true)"
            >新增
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="openExportSizeInput"
              :loading="exportLoading"
            >导出
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="datalist.content"
          size="mini"
          border
          highlight-current-row
          v-loading="loading"
          :max-height="clientHeight"
          style="width: 100%"
        >
          <el-table-column type="index" label="#" align="center" width="55"/>
          <el-table-column
            prop="locale_name"
            label="监测点"
            width="160"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="addr"
            label="地址"
            width="160"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="device_mns"
            label="设备编号"
            align="center"
            width="140"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="create_at"
            label="派单时间"
            align="center"
            width="140"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="user_name"
            label="派单人"
            align="center"
            width="140"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="management_name"
            label="派单类型"
            align="center"
            width="140"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="handle_name"
            label="处理人"
            align="center"
            width="140"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="remark"
            label="处理信息"
            align="center"
            width="140"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="handle_at"
            label="处理日期"
            align="center"
            width="140"
            header-align="center"
          />
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            min-width="120"
            header-align="center"
          >
            <template slot-scope="scope">

              <el-button
                type="text" size="mini"
                :disabled="scope.row.status !== 0"
                @click="handleDialogOpen(scope.row, '处理', true)"
              >处理
              </el-button>
              <el-button
                type="text" size="mini"
                @click="handleDialogOpen(scope.row, '查看', false)"
              >查看
              </el-button>
              <el-button
                type="text" size="mini"
                @click="handleDelete(scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 工具条 -->
    <div class="toolbar" style="text-align:center">
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      ></el-pagination>
    </div>

    
    <!--处理界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      @close="handleClose"
      width="550px"
      :disabled="dlg.isView"
    >
      <el-form
        :model="formData"
        :rules="formRule"
        ref="form"
        size="mini"
        label-width="108px"
        label-position="left"
        :disabled="dlg.isView"
      >
        <el-form-item prop="locale_name" label="监测点">
          <div v-if="dlg.title!=='新增'">{{ formData.locale_name }}</div>
          <el-select
            v-else
            filterable
            style="width: 100%"
            :filter-method="localeFilter"
            :disabled="dlg.title==='处理'"
            v-model="formData.locale_name"
            @change="handleLocaleSelect"
          >
            <el-option v-for="item in this.localeList"
                       :key="item.id"
                       :value="item.name"
                       :label="item.name"/>
          </el-select>
        </el-form-item>
        <el-form-item v-if="formData.addr" prop="addr" label="监测点地址">
          <div>{{ formData.addr }}</div>
        </el-form-item>
        <el-form-item v-if="formData.contact" prop="contact" label="联系人">
          <div>{{ formData.contact }}</div>
        </el-form-item>
        <el-form-item v-if="formData.mobile" prop="mobile" label="联系电话">
          <div>{{ formData.mobile }}</div>
        </el-form-item>
        <el-form-item v-if="formData.device_mns" prop="device_mns" label="设备编号">
          <div>{{ formData.device_mns }}</div>
        </el-form-item>
        <el-form-item v-if="dlg.title !=='新增'" prop="create_at" label="派单日期">
          <div>{{ formData.create_at }}</div>
        </el-form-item>
        <el-form-item prop="management_type" label="派单类型">
          <el-select
            v-model="formData.management_type"
            clearable
            :disabled="dlg.title==='处理'"
            placeholder='派单类型'
            style="width: 100%"
          >
            <el-option :value="1" label="例行巡检"/>
            <el-option :value="2" label="现场监督"/>
            <el-option :value="3" label="执法管理"/>
          </el-select>
        </el-form-item>
        <el-form-item prop="reason" label="派单原因">
          <el-input  v-model="formData.reason" placeholder="派单原因"/>
        </el-form-item>
        <template v-if="eventRecords.length>0">
          <el-form-item label="处理记录">
            <el-timeline>
              <el-timeline-item v-for="item in eventRecords" :key="item.index" :timestamp="'第'+item.index+'次处理记录'" placement="top">
                <el-card>
                  <h4>处理日期: <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{dateFormat(item.handle_at)}}</span></h4>
                  <h4>处理人员: <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{item.handle_name}}</span></h4>
                  <h4>处理措施: <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{item.solution}}</span></h4>
                  <div v-if="item.handle_pics.length > 0">
                    <el-image v-for="(pic,index) in item.handle_pics" :key="index" :src="pic" :preview-src-list="item.handle_pics" style="width: 100px;height: 100px;margin-left:10px;" />
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </el-form-item>
        </template>
        <el-form-item
          prop="handle_name"
          label="处理人员"
          v-if="dlg.title ==='查看'"
        >
          <div>{{ formData.handle_name }}</div>
        </el-form-item>
        <el-form-item
          prop="remark"
          label="处理信息"
          v-if="dlg.title !=='新增'">
          <div v-if="dlg.title === '查看'">{{ formData.remark }}</div>
          <el-input
            v-else
            type="textarea"
            autosize
            v-model.trim="formData.remark"
          />
        </el-form-item>
        <el-form-item
          v-if="dlg.title ==='处理'"
          prop="status"
          label="处理结果"
        >
          <el-select
            style="width: 100%"
            v-model="formData.status"
            clearable
            placeholder='处理结果'
          >
            <el-option label="处理中" :value="0"></el-option>
            <el-option label="已完成" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理图片"  v-if="dlg.title =='处理' || dlg.title =='查看'">
          <el-upload
            ref="upload"
            accept=".jpg, .jpeg, .png"
            list-type="picture-card"
            :show-file-list="false"
            :action="upload()"
            :on-success="handleAvatarSuccess"
            :before-upload="hasChange"
            v-if="dlg.title =='处理'"
          >
           <i class="el-icon-plus"/>
           <div slot="tip">上传照片（支持jpg、jpeg、png）</div>
          </el-upload>
          <div class="img-list" v-if="fileList.length > 0">
            <div class="single-img" v-for="(item, idx) in fileList" :key="idx" style="position: relative; display: inline-block; margin-right: 10px;">
              <el-image
                style="width: 100px;height: 100px"
                :src="item"
                fit="fit"
                :preview-src-list="fileList"/>
              <div v-if="dlg.title =='处理'" class="remove-img el-icon-close" @click="removeImg(idx)"></div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="dlg.title !=='查看'">
        <el-button type="primary" size="mini" @click.native="handleSubmit()">确定</el-button>
        <el-button type="primary" size="mini" @click.native="dlg.visible = false">取消</el-button>
      </div>
    </el-dialog>
    <!--    图片预览dialog-->
    <el-dialog :visible.sync="dlg.showImg">
      <el-image
        :src="dlg.dialogImageUrl"
        fit="fill"
      >
        <div slot="error" class="image-slot">
          暂无图片
        </div>
      </el-image>
    </el-dialog>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import {
  dateFormater,
  getUserInfo,
  export_json, ownerJoin, maintenanceStatusFormatter,maintenanceTypeFormatter
} from '@/util'

import conf from '@/config'

export default {
  name: 'EnforceManagement',
  data() {
    return {
      conf,
      changeState: false,
      exportLoading: false,
      loading: false,
      datalist: {},
      userInfo: null,
      dlg: {
        dialogSubmitted: false,
        visible: false,
        isDeal: false,
        isView: false,
        showImg: false,
        dialogImageUrl: '',
        title: ''
      },
      filter: {
        page: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50]
      },
      fileList: [],
      formData: {},
      eventRecords:[],
      formRule: {
        locale_name: [{required: true, message: '监测点不可为空'}],
        remark: [{required: true, message: '处理信息不可为空'}],
        reason: [{required: true, message: '派单原因不可为空'}],
        management_type: [{required: true, message: '派单类型不可为空'}]
      },
      localeList: []
    }
  },
  computed: {
    ...mapState({
      customerProps: state => Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    }),
    ...mapState(['props', 'clientHeight', 'customerTree', 'locale', 'user'])
  },
  created() {
    this.userInfo = getUserInfo().Info
    this.getData()
  },
  methods: {
    /* 图片模块 start */
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    removeImg(idx) {
      this.changeState = true
      this.fileList.splice(idx, 1)
    },
    handleAvatarSuccess(res, file) {
      this.fileList.push(res.Data)
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    hasChange() {
      this.changeState = true
    },
    getData() {
      this.loading = true
      const payload = {
        page: this.filter.page,
        size: this.filter.size,
        status: this.filter.status,
        management_type: this.filter.management_type ? this.filter.management_type : ''
      }
      if (this.filter.owner && this.filter.owner.length > 0) {
        payload.owner = ownerJoin(this.filter.owner, this.userInfo)
      }
      if (this.filter.start_time) {
        payload.start_time = this.filter.start_time / 1000
        payload.end_time = payload.start_time + 60 * 60 * 24
      }

      this.$get('admin/handle_management', payload).then(res => {
        this.datalist.content = res.list
        this.datalist.total = res.count
        this.loading = false
      })
    },
    handleRefresh() {
      this.filter.page = 1
      this.getData()
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    getEventInfo(id) {
      this.eventRecords = []
      this.$get('admin/listEvent/info/' + id,{typ: 3}).then(res => {
        for(var i = 0; i < res.eventHandleRecords.length; i++) {
          res.eventHandleRecords[i].index = i+1
          if (res.eventHandleRecords[i].handle_pics != "") {
            res.eventHandleRecords[i].handle_pics = res.eventHandleRecords[i].handle_pics.split(",")
          } else {
            res.eventHandleRecords[i].handle_pics = []
          }
        }
        this.eventRecords = res.eventHandleRecords
      })
    },
    handleDialogOpen(row, title, canEdit) {
      this.dlg.dialogSubmitted = false
      this.dlg.title = title
      this.fileList = []
      canEdit ? this.dlg.isDeal = true : this.dlg.isView = true
      if (this.dlg.title === '新增') {
        this.eventRecords = []
        this.formData = {}
        this.dlg.visible = true
      } else {
        this.$get('admin/handle_management/info/' + row.id).then(res => {
          this.formData = res
          if (this.dlg.title === '查看' || this.dlg.title === '处理') {
            if (this.formData.pics) {
              this.fileList = this.formData.pics.split(',')
            }
          }
          this.dlg.visible = true
        })
        this.getEventInfo(row.id)
      }
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
            .then(() => {
              this.formData.pics = this.fileList
              let param = new URLSearchParams()
              Object.entries(this.formData).forEach(([key, val]) => {
                param.append(key, val)
              })
              if (this.dlg.title === '新增') {
                this.$post('admin/handle_management/add', param).then(res => {
                  this.$message({
                    type: "success",
                    message: '添加成功'
                  })
                  this.dlg.visible = false
                })
              } else if (this.dlg.title === '处理') {
                this.$post('admin/handle_management/edit/' + this.formData.id, param).then(res => {
                  this.$message({
                    type: "success",
                    message: '处理成功'
                  })
                  this.dlg.visible = false
                })
              }
            })
            .finally(() => {
              this.dlg.dialogSubmitted = true
            })
        }
      })
    },
    handleClose() {
      if (this.dlg.dialogSubmitted) {
        this.handleRefresh()
      }
      this.dlg.isView = false
      this.dlg.isDeal = false
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
    },
    handleDownload(exportSize) {
      this.exportLoading = true
      const payload = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: exportSize,
        status: this.filter.status,
        management_type: this.filter.management_type,
        is_download: 1
      }
      if (this.filter.owner && this.filter.owner.length > 0) {
        payload.owner = ownerJoin(this.filter.owner, this.userInfo)
      }
      if (this.filter.start_time) {
        payload.start_time = this.filter.start_time / 1000
        payload.end_time = payload.start_time + 60 * 60 * 24
      }

      this.$get('admin/handle_management', payload).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
        this.exportLoading = false
      })
    },
    handleDelete(row) {
      this.$post('admin/handle_management/delete/' + row.id).then(res => {
        this.$message({
          type: 'success',
          message: '删除成功'
        })
        this.getData()
      })
    },
    localeFilter(val) {
      this.$get('admin/list_locale', {name: val}).then(res => {
        this.localeList = res
      })
    },
    handleLocaleSelect(val) {
      let item = this.localeList.find(item => item.name === val)
      this.formData.locale_id = item.id
      this.formData.addr = item.addr
      this.formData.contact = item.contact
      this.formData.device_mns = item.device_mns
      this.formData.mobile = item.mobile
    },
    dateFormat(r) {
      return dateFormater(r, true);
    },
  }
}
</script>
